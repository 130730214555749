@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.equalizer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17px;
  height: 20px;
}

.bar {
  width: 2px;
  height: 100%;
  background-color: white;
  animation: bounce 1s infinite ease-in-out;
}

.bar:nth-child(2) {
  animation-delay: -0.2s;
}

.bar:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes bounce {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.3); /* Adjust the scale to your preference */
  }
}
